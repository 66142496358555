import React from "react";
import SocialLink from "../SocialLink/SocialLink";
import platformData from "../../data/platforms";
import "./App.css";
import CookieConsent from "react-cookie-consent";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";

// Images
import coverArt from "../../images/CoverArt.jpeg"; // relative path to image
import logo from "../../images/logo.png"; // relative path to image
import sozlogo from "../../images/SOZLogo.svg"; // relative path to image
import introLogo from "../../images/SOZIntroLogo.svg"; // relative path to image

function InitializeAnalytics() {
  ReactGA.initialize("UA-158269837-1");
  ReactGA.pageview("/home");
}
const tagManagerArgs = {
  gtmId: "GTM-K3FLX8W"
};

function App() {
  TagManager.initialize(tagManagerArgs);
  InitializeAnalytics();
  return (
    <div className="App">
      <CookieConsent
        location="bottom"
        buttonText="Got it!"
        cookieName="SOZCookie"
        style={{
          background: "#970040",
          justifyContent: "center",
          alignItems: "center"
        }}
        containerClasses="cookie-banner"
        contentClasses="cookie-banner-text"
        buttonStyle={{
          background: "#224d6e",
          color: "#030209",
          fontSize: "10px",
          padding: "10px 30px"
        }}
        expires={10}
        // debug={true}
      >
        This website uses cookies to enhance the user experience.{" "}
      </CookieConsent>
      <div className="intro">
        <img
          className="intro-logo"
          alt="Servants of Zahra Logo"
          src={introLogo}
        />
      </div>
      <section id="page-one" className="section">
        <div className="content">
          <div className="heading">
            <h1 className="title">From the One</h1>
            {/* <h2 className="artist album">Muharram Album 2020</h2> */}
            <h2 className="artist">Servants of Zahra</h2>
          </div>
          <div className="middle">
            <img className="cover" alt="Cover Art" src={coverArt} />
            <div className="link">
              {platformData.map(platform => (
                <SocialLink
                  key={platform.name}
                  name={platform.name}
                  image={platform.image}
                  url={platform.url}
                  // style={platform.style}
                />
              ))}
            </div>
          </div>
          <img className="logo" alt="Servants of Zahra Logo" src={sozlogo} />
        </div>
        <footer>
          <p>ⓒ 2020 Servants of Zahra</p>
          <p>
            Powered by <a href="https://snakebyte.ca"><span role="img" aria-label="snake">🐍</span>Snakebyte Studios</a>
          </p>
        </footer>
      </section>

      {/* <section id="page-two" className="section">
        
      </section> */}
    </div>
  );
}

export default App;
