import React from "react";
import "./SocialLink.css";
import ReactGA from "react-ga";

function SocialLink({ name, image, url, style }) {
  const ClickHandler = () => {
    if(name === "youtube") {
      ReactGA.event({
        category: "Link",
        action: "Youtube"
      });
    } else if (name === "appleMusic") {
      ReactGA.event({
        category: "Link",
        action: "Apple Music"
      });
    } else {
        ReactGA.event({
        category: "Link",
        action: "Spotify"
      });
    }

  };
  return (
    <div className={`social-link ${name}`} id={`GA-${name}`}>
      <a
        onClick={ClickHandler}
        target="_blank"
        without
        rel="noopener noreferrer"
        href={url}
      >
        <img src={image} alt="social link"></img>
      </a>

      {/* <ReactGA.OutboundLink
        eventLabel="myLabel"
        to={url}
        target="_blank"
        trackerNames={name}
      >
        <img src={image} alt="social link"></img>
      </ReactGA.OutboundLink> */}
      {/* <span style={style} /> */}
    </div>
  );
}

export default SocialLink;
