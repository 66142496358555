
// Images
// import spotifyImage from "../images/spotify.png"; // relative path to image
// import appleMusicImage from "../images/applemusic.png"; // relative path to image
// import youtubeImage from "../images/youtube.png"; // relative path to image

import spotifyImage from "../images/spotify.png"; // relative path to image
import appleMusicImage from "../images/applemusic.png"; // relative path to image
import youtubeImage from "../images/youtube.png"; // relative path to image

export default [
  {
    name: "youtube",
    image: youtubeImage,
    url: "https://youtu.be/XXOxDXyIjAM", //STORYBLOK VAR
    // style: {
    //     background: "red"
    // }
  },
  {
    name: "appleMusic",
    image: appleMusicImage,
    url: "https://music.apple.com/ca/album/from-the-one/1581153049?i=1581153050", //STORYBLOK VAR
    // style: {
    //     background: "red"
    // }
  },
  {
    name: "spotify",
    image: spotifyImage,
    url: "https://open.spotify.com/track/3GLDzdApWNoFTPiAVABSjk?si=7d70196c0ba44c0f", //STORYBLOK VAR
    // style: {
    //     background: "red"
    // }
  }
];
